<template>
  <div v-if="false" class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-8 align-center justify-space-between">
      <span class="font-weight-bold">
        Module Learning
      </span>
      <span class="caption-small">
        Home > Module Learning
      </span>
    </div>
    <v-row no-gutters>
      <v-col col="12" class="col-md-4 px-md-4">
        <v-btn v-if="$store.state.user.role !== 'reseller'" dark depressed small block color="green" class="text-capitalize" @click.prevent="MODULE_OPEN()">
          <v-icon class="mr-1 body-1">
            mdi-plus-circle-outline
          </v-icon>
          <span class="caption-small">
            Add New Module
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="px-0 px-md-4">
        <v-card-text class="px-0" :class="[$store.state.user.role !== 'reseller' ? '' : 'pt-1']">
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <v-menu
                ref="rangeDateMenu"
                v-model="rangeDateMenu"
                :return-value.sync="rangeDates"
                left
                offset-y
                :close-on-content-click="false"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="rangeDateText"
                    label="Filter by Dates"
                    prepend-inner-icon="mdi-calendar-outline"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                    class="accent"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="rangeDates = []"
                  />
                </template>
                <v-date-picker
                  v-model="rangeDates"
                  no-title
                  range
                >
                  <v-spacer />
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="rangeDateMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="$refs.rangeDateMenu.save(rangeDates)"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-select
                v-model="searchModulesByType"
                :items="[
                  { value: '', name: 'All' },
                  { value: 'pdf', name: 'PDF' },
                  { value: 'video', name: 'Video' }
                ]"
                item-text="name"
                item-value="value"
                label="Select Type"
                outlined
                dense
                hide-details
                autocomplete="off"
                :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                class="accent mb-4"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="searchModules"
                label="Search Module"
                outlined
                autocomplete="off"
                append-icon="mdi-magnify"
                :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                class="accent"
                block
                dense
                hide-details
              />
            </v-col>
          </v-row>
          <v-divider class="mt-4 mb-2" />
          <v-row>
            <v-col v-for="(l, iL) in learning.data" :key="'learning-' + iL" cols="12" class="col-md-3 px-1 px-md-3 mb-4">
              <v-card class="rounded-lg accent pb-2">
                <div class="c-pointer" style="width: 100%;border-radius: 7px 7px 0px 0px;overflow: hidden;" @click.prevent="ShowLearningDialog(l)">
                  <div class="cover-img" style="background-color: rgba(100, 100, 100, .2);" :style="{ paddingBottom: '60%', backgroundImage: 'url(' + (l.cover || '/images/no-image.jpg') + ')' }" />
                </div>
                <div class="d-flex align-center justify-space-between px-4 pt-3">
                  <div class="d-flex align-center">
                    <v-chip class="mr-1 caption-small text-capitalize white--text" x-small :color="l.content_type === 'pdf' ? 'purple' : 'orange darken-1'" style="padding-bottom: 2px;">
                      {{ l.content_type }}
                    </v-chip>
                    <v-btn x-small icon class="rounded-lg ml-1" :href="l.content" target="_blank">
                      <v-icon small>
                        mdi-open-in-new
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-btn v-if="$store.state.user.role === 'pusat' || ($store.state.user.role !== 'reseller' && parseInt($store.state.user.id) === parseInt(l.user))" x-small icon class="subtitle-1" @click.prevent="MODULE_OPEN(l)">
                    <v-icon small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </div>
                <div class="px-4 caption-small">
                  {{ l.title }}
                </div>
                <div class="px-4" style="font-size: 9px;">
                  {{ $localDT(l.created, 'datetime') }} <small>by</small> {{ l.creator_name }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <v-pagination
                  v-model="mediaPage"
                  :length="Math.ceil(learning.total / mediaLimit)"
                  :total-visible="7"
                  circle
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <v-dialog
      v-model="learningDialog.show"
      persistent
      :scrollable="learningDialog.data ? learningDialog.data.content_type === 'pdf' : false"
      max-width="900"
    >
      <v-card v-if="learningDialog.show && learningDialog.data" class="pb-2">
        <v-card-title class="caption pt-3 pb-2">
          <v-spacer />{{ learningDialog.data.title }}<v-spacer />
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="learningDialog.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="learningDialog.data.content_type === 'video'" class="pa-0">
          <div class="player-container">
            <vue-core-video-player v-if="learningDialog.show" @play="''" :src="learningDialog.data.content" />
          </div>
        </v-card-text>
        <v-card-text v-if="learningDialog.data.content_type === 'pdf'" class="pa-0" style="min-height: calc(100vh - 177px);">
          <PdfViewer v-if="learningDialog.show && learningDialog.data.content_type === 'pdf'" :src="learningDialog.data.content" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newModule.show"
      max-width="550"
      scrollable
      light
    >
      <v-card v-if="newModule.data">
        <v-card-title
          class="caption justify-center py-3 subtitle-1"
        >
          {{ parseInt(newModule.data.id) ? 'Update Module' : 'Upload New Module' }}
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="newModule.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div class="px-2 pt-4 pb-0">
            <v-text-field
              v-model="newModule.data.title"
              label="Module Title"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
            <v-select
              v-model="newModule.data.content_type"
              :items="[
                { value: 'pdf', name: 'PDF' },
                { value: 'video', name: 'Video' }
              ]"
              item-text="name"
              item-value="value"
              label="Content Type"
              outlined
              dense
              hide-details
              autocomplete="off"
              :disabled="newModule.data.content ? true : false"
              class="mb-4"
            />
            <v-row no-gutters class="d-flex pb-6 px-0">
              <v-col v-if="false" cols="6" class="fill-height">
                <v-btn
                  block
                  class="mt-2 mb-4"
                  outlined
                  :color="newModule.data.content ? 'green' : ''"
                  @click.prevent="UPLOAD_FILE(newModule.data.content_type === 'video' ? '.mp4' : '.pdf')"
                >
                  <span v-if="parseInt(newModule.data.id)">
                    Change File
                  </span>
                  <span v-else-if="newModule.data.content">
                    <v-icon class="mr-1 body-1 mb-0">
                      mdi-check-circle-outline
                    </v-icon>
                    File {{ newModule.data.content_type }} Uploaded
                  </span>
                  <span v-else>
                    Upload {{ newModule.data.content_type }} File
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pr-2">
                <span class="caption-small" style="letter-spacing: .0px;">
                  File ({{ newModule.data.content_type }})
                </span>
                <div class="d-flex flex-column flex-sm-row">
                  <div class="mr-2">
                    <div class="d-flex align-center justify-center grey rounded-lg lighten-4 pa-2 c-pointer" style="width: 120px;height: 120px;" @click.prevent="UPLOAD_FILE(newModule.data.content_type === 'video' ? '.mp4' : '.pdf')">
                      <v-icon v-if="!newModule.data.content">
                        {{ newModule.data.content_type === 'video' ? 'mdi-video-plus' : 'mdi-pdf-box' }}
                      </v-icon>
                      <v-icon color="green" v-else>
                        mdi-sticker-check-outline
                      </v-icon>
                      <span v-if="newModule.data.content" class="caption-small pl-1">
                        Uploaded
                      </span>
                    </div>
                  </div>
                  <div :class="[newModule.data.content ? 'justify-space-between' : 'justify-center']" class="d-flex fill-height flex-column pt-2 pt-sm-0">
                    <div class="caption-small mb-2" style="line-height: 130%;">
                      Recommended file size: no more than 25MB (pdf) and 50MB (video)
                    </div>
                    <v-btn v-if="newModule.data.content" depressed dark x-small color="red" class="text-capitalize caption-small rounded-xl px-1" style="width: 75px;" @click.prevent="RemoveFile('content')">
                      <v-icon x-small class="mr-1">
                        mdi-delete
                      </v-icon>
                      Hapus
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="6" class="pl-2">
                <span class="caption-small" style="letter-spacing: .0px;">
                  Thumbnail
                </span>
                <div class="d-flex flex-column flex-sm-row">
                  <div class="mr-2">
                    <v-img
                      :src="newModule.data.cover || '/images/no-image.jpg'"
                      :lazy-src="newModule.data.cover || '/images/no-image.jpg'"
                      aspect-ratio="1"
                      height="120"
                      width="120"
                      class="grey lighten-4 rounded-lg c-pointer pa-2"
                      @click.prevent="UPLOAD_IMAGE()"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                  <div :class="[newModule.data.cover ? 'justify-space-between' : 'justify-center']" class="d-flex fill-height flex-column pt-2 pt-sm-0">
                    <div class="caption-small mb-2" style="line-height: 130%;">
                      Recommended size (300x300)
                    </div>
                    <v-btn v-if="newModule.data.cover" depressed dark x-small color="red" class="text-capitalize caption-small rounded-xl px-1" style="width: 75px;" @click.prevent="RemoveFile('cover')">
                      <v-icon x-small class="mr-1">
                        mdi-delete
                      </v-icon>
                      Hapus
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2 mb-6">
              <v-col v-if="$store.state.user.role === 'pusat'" cols="12" class="col-md-4 py-2">
                <v-checkbox
                  v-model="newModule.data.for_distributor"
                  label="Distributor"
                  class="mt-0"
                  color="green"
                  dense
                  hide-details
                />
              </v-col>
              <v-col v-if="$store.state.user.role === 'pusat' || $store.state.user.role === 'distributor'" cols="12" class="col-md-4 py-2">
                <v-checkbox
                  v-model="newModule.data.for_agen"
                  label="Agen"
                  dense
                  color="green"
                  class="mt-0"
                  hide-details
                />
              </v-col>
              <v-col cols="12" class="col-md-4 py-2">
                <v-checkbox
                  v-model="newModule.data.for_reseller"
                  label="Reseller"
                  class="mt-0"
                  color="green"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
            <v-select
              v-model.number="newModule.data.status"
              :items="[
                { value: 0, name: 'Not active' },
                { value: 1, name: 'Active' }
              ]"
              item-text="name"
              item-value="value"
              label="Module Status"
              outlined
              dense
              autocomplete="off"
              hide-details
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end px-8 pb-2">
          <!-- <v-btn
            outlined
            color="orange"
            small
            class="mr-1 text-capitalize"
            @click="newModule.show = false"
          >
            Close
          </v-btn> -->
          <v-btn
            v-if="parseInt(newModule.data.id)"
            text
            color="red"
            small
            class="text-capitalize"
            @click="MODULE_DELETE()"
          >
            Delete
          </v-btn>
          <v-spacer />
          <v-btn
            depressed
            :color="parseInt(newModule.data.id) ? 'blue darken-1' : 'green darken-1'"
            class="text-capitalize rounded-xl px-4"
            dark
            small
            @click="MODULE_PROCESS()"
          >
            {{ newModule.data.id ? 'Update' : 'Create' }}
          </v-btn>
          <v-spacer v-if="!parseInt(newModule.data.id)" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadingUpload"
      hide-overlay
      persistent
      light
      width="300"
    >
      <v-card light>
        <v-card-title
          class="caption justify-center py-3 subtitle-1"
        >
          Uploading
        </v-card-title>
        <v-divider />
        <v-card-text class="d-flex flex-column pt-2 align-center justify-center">
          <div>
            <strong>{{ $store.state.progress }}%</strong> Uploading, please wait ...
          </div>
          <v-progress-linear
            :value="$store.state.progress"
            color="blue"
            class="mt-2 mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let searchTimeout = null
let searchModulesTimeout = null
export default {
  data: () => ({
    dialogModuleMobile: false,
    learningDialog: {
      show: false,
      data: null
    },
    tabActive: 'featured',
    search: '',
    searchModules: '',
    newPost: {
      show: false,
      text: ''
    },
    newModule: {
      show: false,
      data: null
    },
    forum: {
      total: 0,
      data: []
    },
    learning: {
      total: 0,
      data: []
    },
    mediaPage: 1,
    mediaLimit: 12,
    loadingUpload: false,
    searchModulesByType: '',
    rangeDates: [],
    rangeDateMenu: false
    // videoSource: [
    //   {
    //     src: 'https://media.vued.vanthink.cn/sparkle_your_name_am360p.mp4',
    //     resolution: '360p'
    //   }, {
    //     src: 'https://media.vued.vanthink.cn/sparkle_your_name_am720p.mp4',
    //     resolution: '720p'
    //   }, {
    //     src: 'https://media.vued.vanthink.cn/y2mate.com%20-%20sparkle_your_name_amv_K_7To_y9IAM_1080p.mp4',
    //     resolution: '1080p'
    //   }
    // ]
  }),
  watch: {
    search: function (q) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.LoadForum(q)
        clearTimeout(searchTimeout)
      }, 300)
    },
    searchModules: function (q) {
      if (searchModulesTimeout) {
        clearTimeout(searchModulesTimeout)
      }
      searchModulesTimeout = setTimeout(() => {
        this.LoadLearning(q)
        clearTimeout(searchModulesTimeout)
      }, 300)
    },
    mediaPage: function () {
      this.LoadLearning(this.searchModules)
    },
    searchModulesByType: function (t) {
      this.LoadLearning(this.searchModules, t)
    },
    rangeDates: function () {
      this.LoadLearning(this.searchModules, this.searchModulesByType)
    }
  },
  computed: {
    rangeDateText () {
      return this.rangeDates.join(' ~ ')
    }
  },
  mounted () {
    // this.LoadForum('', 1)
    this.LoadLearning()
  },
  methods: {
    async RemoveFile (what) {
      await this.$store.dispatch('POPUP', {
        t: this,
        show: true,
        type: 'confirm',
        title: 'Confirm',
        text: 'Remove ' + what + '?'
      }).then((res) => {
        this.newModule.data.cover = ''
        if (res.result) {
          this.newModule.data[what] = ''
        }
      })
    },
    SaveNewPost (r) {
      this.newPost.text = r
    },
    ShowLearningDialog (d) {
      if (d.content_type === 'pdf') {
        const a = document.createElement('a')
        a.setAttribute('target', '_blank')
        a.setAttribute('href', d.content)
        a.setAttribute('style', 'overflow: hidden;position: absolute;width: 1px;height:1px;bottom: -1px;left: -1px;')
        document.body.appendChild(a)
        a.click()
        a.parentNode.removeChild(a)
      } else {
        this.learningDialog.data = Object.assign({}, d)
        this.learningDialog.show = true
      }
    },
    SendComment (index, commentID) {
      const media = Object.assign({}, this.forum.data[index])
      if (!commentID && !media.new_comment) {
        return false
      } else {
        const commentParams = {
          media: media.id,
          comment: media.new_comment,
          comment_lead: 0
        }
        const mediaCommentIndex = this.forum.data[index].comments.findIndex(r => parseInt(r.id) === parseInt(commentID))
        if (parseInt(commentID) && mediaCommentIndex > -1) {
          commentParams.comment = this.forum.data[index].comments[mediaCommentIndex].new_comment
          commentParams.comment_lead = commentID
          this.forum.data[index].comments[mediaCommentIndex].new_comment = ''
        } else {
          this.forum.data[index].new_comment = ''
        }
        if (!this.$StipHTML(commentParams.comment)) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Comment is empty' })
          return false
        }
        this.$store.dispatch('MEDIA_COMMENT_ADD', commentParams).then((res) => {
          if (res.status) {
            if (parseInt(commentID)) {
              this.forum.data[index].comments[mediaCommentIndex].comments.unshift(Object.assign({ name: this.$store.state.user.name, img: this.$store.state.user.img }, res.data.data))
              this.forum.data[index].comments[mediaCommentIndex].comments_total += 1
            } else {
              this.forum.data[index].comments.unshift(Object.assign({ name: this.$store.state.user.name, img: this.$store.state.user.img }, res.data.data))
              this.forum.data[index].comments_total += 1
            }
          } else {
            this.forum.data[index].new_comment = media.new_comment
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    removePost (id, mediaIndex, commentIndex, subcommentIndex) {
      let type = ''
      if (subcommentIndex === 0 || subcommentIndex > 0) {
        if (!confirm('Remove this comment ?')) {
          return false
        }
        type = 'comment'
        this.forum.data[mediaIndex].comments[commentIndex].comments.splice(subcommentIndex, 1)
        this.forum.data[mediaIndex].comments[commentIndex].comments_total = parseInt(this.forum.data[mediaIndex].comments[commentIndex].comments_total) - 1
      } else if (commentIndex === 0 || commentIndex > 0) {
        if (!confirm('Remove this comment ?')) {
          return false
        }
        type = 'comment'
        this.forum.data[mediaIndex].comments.splice(commentIndex, 1)
        this.forum.data[mediaIndex].comments_total = parseInt(this.forum.data[mediaIndex].comments_total) - 1
      } else if (mediaIndex === 0 || mediaIndex > 0) {
        if (!confirm('Archive this post ?')) {
          return false
        }
        type = 'media'
        this.forum.data.splice(mediaIndex, 1)
        this.forum.total = parseInt(this.forum.total) - 1
      }
      if (type === 'media') {
        this.$store.dispatch('MEDIA_UPDATE', {
          id: id,
          status: 99
        }).then((res) => {
          // console.log(res)
        })
      } else if (type === 'comment') {
        this.$store.dispatch('MEDIA_COMMENT_UPDATE', {
          id: id,
          status: 99
        }).then((res) => {
          // console.log(res)
        })
      }
    },
    unremovePost (id, mediaIndex, commentIndex, subcommentIndex) {
      let type = ''
      if (subcommentIndex === 0 || subcommentIndex > 0) {
        if (!confirm('Unarchive this comment ?')) {
          return false
        }
        type = 'comment'
        this.forum.data[mediaIndex].comments[commentIndex].comments.splice(subcommentIndex, 1)
        this.forum.data[mediaIndex].comments[commentIndex].comments_total = parseInt(this.forum.data[mediaIndex].comments[commentIndex].comments_total) - 1
      } else if (commentIndex === 0 || commentIndex > 0) {
        if (!confirm('Unarchive this comment ?')) {
          return false
        }
        type = 'comment'
        this.forum.data[mediaIndex].comments.splice(commentIndex, 1)
        this.forum.data[mediaIndex].comments_total = parseInt(this.forum.data[mediaIndex].comments_total) - 1
      } else if (mediaIndex === 0 || mediaIndex > 0) {
        if (!confirm('Unarchive this post ?')) {
          return false
        }
        type = 'media'
        this.forum.data.splice(mediaIndex, 1)
        this.forum.total = parseInt(this.forum.total) - 1
      }
      if (type === 'media') {
        this.$store.dispatch('MEDIA_UPDATE', {
          id: id,
          status: 1
        }).then((res) => {
          // console.log(res)
        })
      } else if (type === 'comment') {
        this.$store.dispatch('MEDIA_COMMENT_UPDATE', {
          id: id,
          status: 1
        }).then((res) => {
          // console.log(res)
        })
      }
    },
    MODULE_OPEN (m) {
      if (m) {
        m.for_distributor = parseInt(m.for_distributor) || false
        m.for_agen = parseInt(m.for_agen) || false
        m.for_reseller = parseInt(m.for_reseller) || false
        m.for_customer = parseInt(m.for_customer) || false
        m.type = m.type || 'learning'
        this.newModule.data = Object.assign({}, m)
      } else {
        this.newModule.data = {
          id: null,
          title: '',
          type: 'learning',
          content_type: 'pdf',
          cover: '',
          content: '',
          for_distributor: this.$store.state.user.role === 'pusat',
          for_agen: this.$store.state.user.role === 'pusat' && this.$store.state.user.role !== 'distributor' && this.$store.state.user.role !== 'agen',
          for_reseller: true,
          for_customer: false,
          status: 1
        }
      }
      this.newModule.show = true
    },
    MODULE_DELETE () {
      const newData = Object.assign({}, this.newModule.data)
      if (newData) {
        if (!confirm('Are you sure want to delete this module ?')) {
          return false
        }
        newData.status = 100
        this.$store.dispatch('MEDIA_UPDATE', newData).then((res) => {
          if (res.status) {
            this.newModule.show = false
            this.LoadLearning(this.searchModules)
            this.$store.commit('SNACKBAR', { show: true, text: 'Module deleted' })
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong' })
          }
        })
      }
    },
    MODULE_PROCESS () {
      const newData = Object.assign({}, this.newModule.data)
      if (!newData) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid input' })
        return false
      } else {
        if (!newData.title) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Please input title' })
          return false
        }
        if (!newData.content) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Please upload file' })
          return false
        }
        newData.for_distributor = newData.for_distributor ? 1 : 0
        newData.for_agen = newData.for_agen ? 1 : 0
        newData.for_reseller = newData.for_reseller ? 1 : 0
        newData.for_customer = newData.for_customer ? 1 : 0
        if (parseInt(newData.id)) {
          this.$store.dispatch('MEDIA_UPDATE', newData).then((res) => {
            if (res.status) {
              this.newModule.show = false
              this.LoadLearning(this.searchModules)
              this.$store.commit('SNACKBAR', { show: true, text: 'Module updated' })
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong' })
            }
          })
        } else {
          this.$store.dispatch('MEDIA_ADD', newData).then((res) => {
            if (res.status) {
              this.newModule.show = false
              this.LoadLearning()
              this.$store.commit('SNACKBAR', { show: true, text: 'New module created' })
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong' })
            }
          })
        }
      }
    },
    CreateNewPost () {
      if (!this.$StipHTML(this.newPost.text)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please write something' })
        return false
      }
      this.$store.dispatch('MEDIA_ADD', {
        type: 'forum',
        content: this.newPost.text
      }).then((res) => {
        if (res.status) {
          this.tabActive = 'all'
          this.newPost.show = false
          this.newPost.text = ''
          this.search = ''
          this.LoadForum()
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    SetFeatured (f) {
      f.featured = parseInt(f.featured) ? 0 : 1
      this.$store.dispatch('MEDIA_UPDATE', f)
    },
    ShowMedia (type) {
      this.tabActive = type
      this.forum.data = []
      this.forum.total = 0
      if (type === 'featured') {
        this.LoadForum(this.search, 1)
      } else if (type === 'archive') {
        this.LoadForum(this.search, '', null, 0, true)
      } else {
        this.LoadForum(this.search, '')
      }
    },
    LoadForum (q, featured, loadMore, lastID, archived) {
      archived = archived ? '&status=99' : '&status=1'
      this.$store.dispatch('MEDIA_FORUM_GET', '?q=' + (q || '') + '&featured=' + (parseInt(featured) || '') + '&last_id=' + (parseInt(lastID || '') || '') + archived).then((res) => {
        if (res.status) {
          this.forum.total = parseInt(res.data.data.total) || 0
          if (loadMore) {
            this.forum.data = this.forum.data.concat((res.data.data.data || []).map((r) => {
              r.comments = []
              r.comments_total = parseInt(r.comments_total) || 0
              r.new_comment = ''
              return r
            }))
          } else {
            this.forum.data = (res.data.data.data || []).map((r) => {
              r.comments = []
              r.comments_total = parseInt(r.comments_total) || 0
              r.new_comment = ''
              return r
            })
          }
        }
      })
    },
    LoadLearning (q, type) {
      let dates = ''
      if (this.rangeDates.length === 2) {
        if ((new Date(this.rangeDates[0])).getTime() < (new Date(this.rangeDates[1])).getTime()) {
          dates = '&from=' + this.rangeDates[0] + '&to=' + this.rangeDates[1]
        } else {
          dates = '&from=' + this.rangeDates[1] + '&to=' + this.rangeDates[0]
        }
      }
      this.$store.dispatch('MEDIA_LEARNING_GET', '?page=' + (this.mediaPage || 1) + '&limit=' + (this.mediaLimit || 16) + '&q=' + (q || '') + (type ? '&type=' + type : '') + (dates || '')).then((res) => {
        if (res.status) {
          this.learning.total = parseInt(res.data.data.total) || 0
          this.learning.data = res.data.data.data || []
        }
      })
    },
    LoadComment (mediaID, mediaIndex, commentID, commentIndex) {
      if (commentID && (parseInt(commentIndex) === 0 || parseInt(commentIndex) > 0)) {
        const comments = this.forum.data[mediaIndex].comments[commentIndex].comments
        this.$store.dispatch('MEDIA_COMMENT_GET', {
          media: mediaID,
          q: '?last_id=' + (comments.length ? comments[comments.length - 1].id : 0) + '&comment_lead=' + commentID
        }).then((res) => {
          if (res.status) {
            const mediaIndex = this.forum.data.findIndex(r => parseInt(r.id) === parseInt(mediaID))
            const index = this.forum.data[mediaIndex].comments.findIndex(r => parseInt(r.id) === parseInt(commentID))
            if (index > -1) {
              this.forum.data[mediaIndex].comments[index].comments = this.forum.data[mediaIndex].comments[index].comments.concat(res.data.data || []).map((r) => {
                r.comments = []
                r.comments_total = parseInt(r.comments_total) || 0
                r.new_comment = ''
                r.toggle = false
                return r
              })
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      } else {
        const comments = this.forum.data[mediaIndex].comments
        this.$store.dispatch('MEDIA_COMMENT_GET', {
          media: mediaID,
          q: '?last_id=' + (comments.length ? comments[comments.length - 1].id : 0)
        }).then((res) => {
          if (res.status) {
            const index = this.forum.data.findIndex(r => parseInt(r.id) === parseInt(mediaID))
            if (index > -1) {
              this.forum.data[index].comments = this.forum.data[index].comments.concat(res.data.data || []).map((r) => {
                r.comments = []
                r.comments_total = parseInt(r.comments_total) || 0
                r.new_comment = ''
                r.toggle = false
                return r
              })
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    UPLOAD_IMAGE (index) {
      const f = document.getElementById('imageUploader')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'imageUploader')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', '.jpg,.jpeg,.png')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, index, true)
      })
      document.body.appendChild(el)
      el.click()
    },
    UPLOAD_FILE (type, index) {
      const f = document.getElementById('fileUploader')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'fileUploader')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', type || '.pdf')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, index)
      })
      document.body.appendChild(el)
      el.click()
    },
    async UPLOAD_HANDLER (event, index, cover) {
      /* eslint-disable */
      const $ = this
      $.loadingUpload = true
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data
          }).then((resUpload) => {
            $.loadingUpload = false
            if (resUpload) {
              if (cover) {
                $.newModule.data.cover = resUpload.data.data.secure_url || ''
              } else {
                $.newModule.data.content = resUpload.data.data.secure_url || ''
                $.$store.commit('SNACKBAR', { show: true, text: 'File uploaded' })
              }
            } else {
              $.$store.commit('SNACKBAR', { show: true, text: 'Failed upload' })
            }
          })
        } else {
          $.loadingUpload = false
          res.data === 'file_size'
            ? $.$store.commit('SNACKBAR', { show: true, text: 'Ops, file image is too large. Max size (images < 25Mb)' })
            : $.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
            // : res.data === 'canceled'
              // ? console.log('Upload canceled.')
              // : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.player-container {
  .vcp-container {
    video {
      display: block;
      max-height: calc(100vh - 200px);
    }
    .btn-control {
      &.btn-play,
      &.btn-pause {
        >svg {
          >path {
            fill: #c6893f!important;
          }
          >g {
            >rect {
              fill: #c6893f!important;
            }
          }
        }
      }
    }
    .vcp-progress-played {
      background-color: #c6893f!important;
    }
  }
}
.ce-paragraph {
  line-height: 1.2em!important;
}
.ce-header {
  padding: .7em 0!important;
  line-height: 1.1em!important;
}
.b-botom {
  border-bottom: solid 1px rgba(100, 100, 100, .2);
}
</style>
